import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import PublicEventsList from './PublicEventsList';
import PublicEventsCalendar from './PublicEventsCalendar';
import { isMobile } from '../../modules/helpers';

// Misc
import styles from './PublicEvents.module.postcss';

export const EVENT_MODE = {
  LIST: 'list',
  CALENDAR: 'calendar',
}

class PublicEvents extends Component {
  constructor(props) {
    super(props);

    this.onActivateList = this.onActivateList.bind(this);
    this.onActivateCalendar = this.onActivateCalendar.bind(this);

    this.state = {
      mode: (isMobile() ? EVENT_MODE.LIST : (props.defaultMode || EVENT_MODE.LIST)),
    };
  }

  onActivateList() {
    this.setState({
      mode: EVENT_MODE.LIST
    });
  }

  onActivateCalendar() {
    this.setState({
      mode: EVENT_MODE.CALENDAR
    });
  }

  render() {
    const { mode, disableLoader } = this.state;
    const { admin, hideModeControls, title } = this.props;

    return (
      <div>
        <div className={styles.Events_controls + " u-mb1"}>
          <h4>{title}</h4>
          {!hideModeControls &&
            <div className={styles.Events_modeControls}>
              <button
                disabled={mode === EVENT_MODE.LIST}
                className="ButtonSet ButtonSet--small ButtonSet--left"
                onClick={this.onActivateList}
              >
                List
              </button>
              <button
                disabled={mode === EVENT_MODE.CALENDAR}
                className="ButtonSet ButtonSet--small ButtonSet--right"
                onClick={this.onActivateCalendar}
              >
                Calendar
              </button>
            </div>
          }
        </div>

        <div>
          {mode === EVENT_MODE.LIST &&
            <PublicEventsList
              {...this.props}
              disableLoader={disableLoader}
              admin={admin}
            />
          }
          {mode === EVENT_MODE.CALENDAR &&
            <PublicEventsCalendar
              {...this.props}
              disableLoader={disableLoader}
              admin={admin}
            />
          }
        </div>
      </div>
    );
  }
}

PublicEvents.propTypes = {
  withActiveTrucks: PropTypes.bool,
  includeLocations: PropTypes.bool,
  defaultMode: PropTypes.string,
  hideModeControls: PropTypes.bool,
  title: PropTypes.string,
};

export default PublicEvents;
