import { normalize } from 'normalizr';
import API from '../modules/api';
import param from 'jquery-param';

// Misc Requirements
import baseActionsFor from './baseActions';
import asyncActionsFor from './asyncActions';
import { displayError } from './errorActions';
import { eventSchema, eventListSchema } from './schemas';

// Create Base Actions
const baseActions = baseActionsFor('events', 'event');

// Create Base Async Actions
const asyncActions = asyncActionsFor({
  baseActions,
  resourceName: 'event',
  listName: 'events',
  resourceSchema: eventSchema,
  listSchema: eventListSchema
});

// Custom Actions and Base Action Overrides
const customActions = {
  removeTruckFromWaitlist(options) {
    return dispatch => {
      dispatch(baseActions.updateStart());

      const promise = API.post(`/api/events/${options.event_id}/remove_truck_from_waitlist`,
        {
          event: {
            waitlist_entry_id: options.waitlist_entry_id
          }
        });

      promise.then((response) => {
        const records = normalize(response, eventSchema);
        dispatch(baseActions.updateSuccess(records));
      });

      promise.catch((response) => {
        dispatch(baseActions.updateError(response));
        if (response.status === 404) {
          dispatch(displayError(404, response.statusText, true));
        } else {
          dispatch(displayError(500, response.statusText, true));
        }
      });

      return promise;
    };
  },
  addTruckToWaitlist(options) {
    return dispatch => {
      dispatch(baseActions.updateStart());

      const promise = API.post(`/api/events/${options.event_id}/add_truck_to_waitlist`,
        {
          event: {
            truck_id: options.truck_id
          }
        });

      promise.then((response) => {
        const records = normalize(response, eventSchema);
        dispatch(baseActions.updateSuccess(records));
      });

      promise.catch((response) => {
        dispatch(baseActions.updateError(response));
        if (response.status === 404) {
          dispatch(displayError(404, response.statusText, true));
        } else {
          dispatch(displayError(500, response.statusText, true));
        }
      });

      return promise;
    };
  },
  updateBooking(options) {
    return dispatch => {
      dispatch(baseActions.updateStart());

      const promise = API.post(`/api/events/${options.event_id}/update_booking?${param(options)}`,
        {
          event: {
            booking: {
              id: options.booking_id, status: options.status
            },
            include_event: options.include_event,
          }
        });

      promise.then((response) => {
        const records = normalize(response, eventSchema);
        dispatch(baseActions.updateSuccess(records));
      });

      promise.catch((response) => {
        dispatch(baseActions.updateError(response));
        if (response.status === 404) {
          dispatch(displayError(404, response.statusText, true));
        } else {
          dispatch(displayError(500, response.statusText, true));
        }
      });

      return promise;
    };
  },
  createBooking(options) {
    return dispatch => {
      dispatch(baseActions.updateStart());

      const promise = API.put(`/api/events/${options.event_id}/create_booking`,
        {
          event: {
            booking: {
              truck_id: options.truck_id,
              recurring: options.recurring ? options.recurring : false,
              recurrence_rule: options.recurrence_rule ? options.recurrence_rule : null,
              recurrence_start: options.recurrence_start ? options.recurrence_start : null,
            }
          }
        });

      promise.then((response) => {
        const records = normalize(response, eventSchema);
        dispatch(baseActions.updateSuccess(records));
      });

      promise.catch((response) => {
        dispatch(baseActions.updateError(response));
        if (response.status === 404) {
          dispatch(displayError(404, response.statusText, true));
        } else if (response.status === 409) {
          // Do nothing
        } else {
          dispatch(displayError(500, response.statusText, true));
        }
      });

      return promise;
    };
  },
  createBookings(options) {
    return dispatch => {
      dispatch(baseActions.updateStart());

      const promise = API.put(`/api/events/${options.event_id}/create_bookings`,
        {
          event: {
            truck_id: options.truck_id,
            recurring: options.recurring ? options.recurring : false,
            recurrence_rule: options.recurrence_rule ? options.recurrence_rule : null,
            recurrence_start: options.recurrence_start ? options.recurrence_start : null,
            occurrences: options.occurrences
          }
        });

      promise.then((response) => {
        response.events.forEach((event) => {
          let record = normalize(event, eventSchema);
          dispatch(baseActions.updateSuccess(record));
        });
      });

      promise.catch((response) => {
        dispatch(baseActions.updateError(response));
        if (response.status === 404) {
          dispatch(displayError(404, response.statusText, true));
        } else if (response.status === 409) {
          // Do nothing
        } else {
          dispatch(displayError(500, response.statusText, true));
        }
      });

      return promise;
    };
  },
  cancelBooking(options) {
    return dispatch => {
      dispatch(baseActions.updateStart());

      const promise = API.post(`/api/events/${options.event_id}/cancel_booking`,
        {
          event: {
            booking: {
              id: options.booking_id,
            }
          },
          cancellation_reason: options.cancellation_reason,
          cancellation_explanation: options.cancellation_explanation
        });

      promise.then((response) => {
        const records = normalize(response, eventSchema);
        dispatch(baseActions.updateSuccess(records));
      });

      promise.catch((response) => {
        dispatch(baseActions.updateError(response));
        if (response.status === 404) {
          dispatch(displayError(404, response.statusText, true));
        } else {
          let message = '';
          if (response.responseJSON && response.responseJSON.base) {
            message = response.responseJSON.base[0];
          }
          dispatch(displayError(500, message, true));
        }
      });

      return promise;
    };
  },
  delete(id) {
    return dispatch => {
      const promise = API.delete(`/api/events/${id}`);

      promise.then((response) => {
        dispatch(this.deleteSuccess(id, response.deleted_occurrences));
      });

      promise.catch((response) => {
        dispatch(baseActions.deleteError(response));
        if (response.status === 404) {
          dispatch(displayError(404, response.statusText, true));
        } else {
          dispatch(displayError(500, response.statusText, true));
        }
      });

      return promise;
    };
  },
  blackoutOccurrence(id) {
    return dispatch => {
      const promise = API.post(`/api/events/${id}/blackout_occurrence`);

      promise.then((response) => {
        dispatch(this.deleteSuccess(id, response.deleted_occurrences));
      });

      promise.catch((response) => {
        dispatch(baseActions.deleteError(response));
        if (response.status === 404) {
          dispatch(displayError(404, response.statusText, true));
        } else {
          dispatch(displayError(500, response.statusText, true));
        }
      });

      return promise;
    };
  },
  addTruck(options) {
    return dispatch => {
      dispatch(baseActions.updateStart());

      const promise = API.post(`/api/events/${options.eventId}/add_truck?include_locations=${options.includeLocations === true}`,
        {
          event: {
            truck_id: options.truckId,
            no_charge: options.noCharge,
            include_event: options.include_event,
          }
        });

      promise.then((response) => {
        const records = normalize(response, eventSchema);
        dispatch(baseActions.updateSuccess(records));
      });

      promise.catch((response) => {
        dispatch(baseActions.updateError(response));
        if (response.status === 404) {
          dispatch(displayError(404, response.statusText, true));
        } else {
          dispatch(displayError(500, response.statusText, true));
        }
      });

      return promise;
    };
  },
  // substituteTruck(options) {
  //   return dispatch => {
  //     dispatch(baseActions.updateStart());

  //     const promise = API.post(`/api/events/${options.eventId}/substitute_truck?include_locations=${options.includeLocations === true}`,
  //       {
  //         event: {
  //           booking: {
  //             id: options.bookingId,
  //             truck_id: options.truckId,
  //           },
  //           include_event: options.include_event,
  //         }
  //       });

  //     promise.then((response) => {
  //       const records = normalize(response, eventSchema);
  //       dispatch(baseActions.updateSuccess(records));
  //     });

  //     promise.catch((response) => {
  //       dispatch(baseActions.updateError(response));
  //       if (response.status === 404) {
  //         dispatch(displayError(404, response.statusText, true));
  //       } else {
  //         dispatch(displayError(500, response.statusText, true));
  //       }
  //     });

  //     return promise;
  //   };
  // },
  fetchEventOccurrence(id, params = null, skipLoader = false) {
    return dispatch => {
      if (!skipLoader) {
        dispatch(baseActions.fetchOneStart());
      }
      const promise = API.get(`/api/events/occurrence/${id}?${param(params)}`);

      promise.then((response) => {
        const records = normalize(response, eventSchema);
        dispatch(baseActions.fetchOneSuccess(records));
      });

      promise.catch((response) => {
        dispatch(baseActions.fetchOneError(response));
        if (response.status === 404) {
          dispatch(displayError(404, response.statusText));
        } else {
          dispatch(displayError(500, response.statusText, true));
        }
      });

      return promise;
    };
  },
  deleteTruck(options) {
    return dispatch => {
      dispatch(baseActions.updateStart());

      const promise = API.post(`/api/events/${options.event_id}/delete_truck?${param(options)}`,
        {
          event: {
            booking_id: options.booking_id,
            include_event: options.include_event,
          }
        });

      promise.then((response) => {
        const records = normalize(response, eventSchema);
        dispatch(baseActions.updateSuccess(records));
      });

      promise.catch((response) => {
        dispatch(baseActions.updateError(response));
        if (response.status === 404) {
          dispatch(displayError(404, response.statusText, true));
        } else {
          dispatch(displayError(500, response.statusText, true));
        }
      });

      return promise;
    };
  },
  deleteSuccess(id, deletedOccurrences) {
    return {
      id,
      deletedOccurrences,
      type: 'EVENT_DELETE_SUCCESS',
    };
  }
};

// Merge Actions
const actions = Object.assign(baseActions, asyncActions, customActions);

// Export
export default actions;
