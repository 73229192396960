import React from 'react';
import { Link } from 'react-router-dom';
import { arrayIntersection } from './helpers';
import dayjs from 'dayjs';

const EventUtils = {
  prepOptions(localOptions, props) {
    const {
      truck, pod, location, neighborhood, shift, onlyPodEvents, onDate,
      includeLocations, withActiveTrucks, onlyWithAvailability, trailersAllowed,
      includeBookings,includeWaitlist, withBookingStatus, onlyBookable, futureBookable, includeOccurrences,
      recurringBooking
    } = props;
    const options = Object.assign({}, localOptions);

    if (truck) options.for_trucks = truck;
    if (pod) options.for_pods = pod;
    if (onDate) options.on_day = onDate;
    if (location) options.for_locations = location;
    if (trailersAllowed) options.trailers_allowed = trailersAllowed;
    if (neighborhood) options.for_neighborhoods = neighborhood;
    if (shift) options.for_shifts = shift;
    if (includeLocations) options.include_locations = true;
    if (onlyPodEvents) options.only_pod_events = true;
    if (futureBookable) options.future_bookable = true;
    if (onlyWithAvailability) options.only_with_availability = true;
    if (onlyBookable) options.only_bookable = true;
    if (withActiveTrucks) options.with_active_trucks = true;
    if (includeBookings) options.include_bookings = true;
    if (includeWaitlist) options.include_waitlist = true;
    if (includeOccurrences) options.include_occurrences = true;
    if (recurringBooking) options.recurring_booking = true;
    if (withBookingStatus) options.with_booking_status = withBookingStatus;

    return options;
  },

  getMissingDocuments(truck, parent_event, cityConfig) {
    if (truck && parent_event && parent_event.pod) {
      const w9_required = parent_event.pod.w9_required;
      const coi_required = parent_event.pod.coi_required;
      const health_required = parent_event.pod.health_required;
      const health_county2_required = parent_event.pod.health_county2_required;

      let missingDocs = [];

      if (w9_required && truck.w9_status !== 'approved') {
        missingDocs.push('W9');
      }
      if (coi_required && truck.coi_status !== 'approved') {
        missingDocs.push('COI');
      }
      if (health_required && truck.health_status !== 'approved') {
        missingDocs.push(cityConfig.county1 + ' County Health Permit');
      }
      if (health_county2_required && truck.health_county2_status !== 'approved') {
        missingDocs.push(cityConfig.county2 + ' County Health Permit');
      }

      return missingDocs;
    }
  },

  getMonthsOverbooked(event, selectedEvents) {
    let maxBookingsPerMonth = event.pod.max_bookings_per_month;
    if (!maxBookingsPerMonth) {
      return [];
    }

    // Calculate events selected per month
    let selectedEventsByMonth = Object.groupBy(selectedEvents, (event) => {
      if (event.available_slots <= 0) {
        return 'unavailable';
      }
      return dayjs(event.start_time).format('MM-YYYY');
    });
    delete selectedEventsByMonth['unavailable'];

    // Calculate months overbooked
    let monthsOverbooked = [];
    let bookingCountByMonth = event.booking_count_for_truck;
    Object.keys(selectedEventsByMonth).forEach((month) => {
      let bookingCount = bookingCountByMonth[month] ? bookingCountByMonth[month] : 0;
      let selectedEventsCount = selectedEventsByMonth[month].length;
      if (bookingCount + selectedEventsCount > maxBookingsPerMonth) {
        monthsOverbooked.push(month);
      }
    });

    monthsOverbooked = monthsOverbooked.map((date) => {
      let month = dayjs().month(date.substring(0,2)).subtract(1, 'month').format("MMMM");
      let year = date.substring(3)
      return( month + " " + year );
    })

    return monthsOverbooked;
  },

  renderErrors(truck, event, basePath, missingDocs) {
    const pod = event.pod;

    // If vendor truck is over max dimensions
    const truckOverMaxLength = (truck.truck_length && pod.max_truck_length && truck.truck_length > pod.max_truck_length);
    const truckOverMaxWidth = (truck.truck_width && pod.max_truck_width && truck.truck_width > pod.max_truck_width);
    if (truckOverMaxLength || truckOverMaxWidth) {
      return (
        <div>We're sorry, your vehicle is too large for this location at this time.</div>
      );
    }

    // If vendor missing truck dimensions
    const truckHasDimensions = (truck.truck_length || truck.truck_width);
    const podHasDimensions = (pod.max_truck_length || pod.max_truck_width);
    if (podHasDimensions && !truckHasDimensions) {
      return (
        <div>You are required to set truck width and length in <Link to={`${basePath}/details`}>Basic Details</Link> before booking this location.</div>
      );
    }

    // If vendor serves same cuisine as pod
    const truck_categories = truck.food_categories.map((category) => category.name);
    let pod_categories = [];
    if (event.pod.food_categories) {
      pod_categories = event.pod.food_categories.map((category) => category.name);
    }
    if (event.event?.pod.food_categories) {
      pod_categories = event.event.pod.food_categories.map((category) => category.name);
    }
    let cuisineMatches = arrayIntersection(truck_categories, pod_categories);
    if (cuisineMatches.length > 0) {
      return (
        <div>We're sorry, due to competing cuisine options, your truck is
        unable to book this location at this time.</div>
      );
    }

    // If trailers aren't allowed, and vendor is a trailer
    if (!pod.trailers_allowed && truck.trailer) {
      return (
        <div>We're sorry, this location is unavailable for trailers at this time.</div>
      );
    }

    // If required documents are missing
    if (missingDocs && missingDocs.length > 0) {
      return (
        <div>Please submit missing documents in order to book this event.</div>
      );
    }

    // No errors
    return null;
  },

  bookingCanBeCancelled(event) {
    const eventNotStarted = dayjs(event.start_time).isAfter(dayjs());
    return eventNotStarted;
  },

  getBooking(truck, event) {
    let booking = null;

    if (event.bookings && event.bookings.length > 0) {
      booking = event.bookings.find((booking) => {
        if (!truck || !booking.truck) return false;
        return booking.truck.id === truck.id;
      });
    }

    return booking;
  },
};

export default EventUtils;
